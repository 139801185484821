import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex-1" }
const _hoisted_4 = { class: "flex flex-ai-c mg-t-12 border fc-black" }
const _hoisted_5 = { class: "flex-1 bold flex flex-ai-c" }
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = { class: "single-line set-w-1" }
const _hoisted_8 = { class: "w-100" }
const _hoisted_9 = { class: "flex mg-t-16" }
const _hoisted_10 = { class: "flex-1 bg-gray" }
const _hoisted_11 = { class: "title bold fs-16 shadow fc-black" }
const _hoisted_12 = {
  key: 0,
  class: "fc-red fs-14"
}
const _hoisted_13 = { class: "flex flex-1 flex-jc-sb mg-r-10" }
const _hoisted_14 = { class: "single-line set-w-1" }
const _hoisted_15 = {
  key: 1,
  class: "set-margin"
}
const _hoisted_16 = {
  key: 0,
  class: "fc-red fs-12 mg-t-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_CircleCloseFilled = _resolveComponent("CircleCloseFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_isOver = _resolveDirective("isOver")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    "modal-class": "reset",
    "before-close": _ctx.handleClose,
    width: "760px"
  }, {
    title: _withCtx(() => _cache[4] || (_cache[4] = [
      _createElementVNode("div", { class: "fs-16 bold" }, " 配置企业 ", -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_input, {
              modelValue: _ctx.searchValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
              onInput: _ctx.handleInput,
              clearable: "",
              class: "w-50 m-2",
              placeholder: "搜索",
              "suffix-icon": "Search"
            }, null, 8, ["modelValue", "onInput"]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_el_checkbox, {
                    modelValue: _ctx.isCheckAll,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isCheckAll) = $event)),
                    size: "small",
                    onChange: _ctx.handleAllChange
                  }, null, 8, ["modelValue", "onChange"]),
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "mg-l-5" }, "企业名称", -1))
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "w-100 bold" }, " 负责人 ", -1))
              ]),
              _createVNode(_component_el_scrollbar, { class: "scroll-box" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companys, (i, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "flex flex-ai-c",
                      key: index
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_el_checkbox, {
                          modelValue: i.selected,
                          "onUpdate:modelValue": ($event: any) => ((i.selected) = $event),
                          size: "small",
                          onChange: ($event: any) => (_ctx.handleCheckboxChange($event, i))
                        }, {
                          default: _withCtx(() => [
                            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createTextVNode(_toDisplayString(i.serveCompanyName), 1)
                            ])), [
                              [_directive_isOver, i.serveCompanyName]
                            ])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
                      ]),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(i.contacts), 1)
                    ]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_el_button, {
                type: "primary",
                size: "small",
                onClick: _ctx.handleSave
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("保存")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_el_button, {
                type: "primary",
                plain: "",
                size: "small",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialogVisible = false))
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("取消")
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createTextVNode(" 已选择" + _toDisplayString(_ctx.selectedCompanys.length) + "个 ", 1),
              (_ctx.errorList.length)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, "有" + _toDisplayString(_ctx.errorList.length) + "家企业负责人出错，请重新选择", 1))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_el_scrollbar, { class: "scroll-box-2 set-pd" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedCompanys, (i, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "flex flex-jc-sb flex-ai-start mg-t-10",
                    key: index
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createTextVNode(_toDisplayString(i.serveCompanyName), 1)
                      ])), [
                        [_directive_isOver, i.serveCompanyName]
                      ])
                    ]),
                    (!i.isError)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          ref_for: true,
                          ref: `content-${index}`
                        }, _toDisplayString(i.contacts), 513))
                      : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createVNode(_component_el_select, {
                            modelValue: i.contactsId,
                            "onUpdate:modelValue": ($event: any) => ((i.contactsId) = $event),
                            size: "small",
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            "value-key": "contactsId",
                            placeholder: "请输入姓名",
                            "remote-method": (ev) => _ctx.remoteMethod(ev, i),
                            onFocus: ($event: any) => (_ctx.getEmployeeList('', i)),
                            onChange: ($event: any) => (_ctx.handleChange($event, i))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.options, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.contactsId,
                                  label: item.contacts,
                                  value: item.contactsId
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue", "remote-method", "onFocus", "onChange"]),
                          (i.showHint)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, "请重新选择负责人"))
                            : _createCommentVNode("", true)
                        ])),
                    _createVNode(_component_el_icon, {
                      class: "pointer mg-t-3 mg-l-10",
                      onClick: ($event: any) => (_ctx.handleRemove(i))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CircleCloseFilled)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}