
import { defineComponent } from 'vue'
import tableComponent from '@/components/table-component/index.vue'
import dialogAdvanceCompany from './components/dialog-advance-company.vue'
import { cloneDeep } from 'lodash'
import {
    getAdvanceConfig,
    setAdvanceConfig,
    deleteAdvanceCompany
} from '@/api/advance.ts'
import { ElMessageBox } from 'element-plus'

const initData = {
    applyCount: 0,
    entryDay: 0,
    isAutoReject: 1,
    applyQuota: '',
    cutOffTime: '',
    applyType: '',
    applyProjectType: ''
} as any
export default defineComponent({
    name: 'AdvanceSetting',
    data() {
        const validateLimitCount = (rule: any, value: any, callback: any) => {
            console.log(rule, value)
            if ((this.formData as any).applyType) {
                if (!(this.formData as any).applyCount) {
                    callback(new Error('请选择限制次数'))
                } else {
                    callback()
                }
            } else {
                if ((this.formData as any).applyType === 0) {
                    callback()
                } else {
                    callback(new Error('请选择限制次数'))
                }
            }
        }

        const validateConditionCount = (
            rule: any,
            value: any,
            callback: any
        ) => {
            if (!(this.formData as any).entryDay) {
                callback(new Error('输入申请条件'))
            } else {
                callback()
            }
        }

        return {
            cutOffTime: '',
            isFirstTime: true,
            hasSet: true,
            formData: {} as any,
            ruleData: {} as any,
            cutOffTimeOptionList: [
                {
                    label: '不限制',
                    value: '-1'
                },
                {
                    label: '00:00',
                    value: '00'
                },
                {
                    label: '01:00',
                    value: '01'
                },
                {
                    label: '02:00',
                    value: '02'
                },
                {
                    label: '03:00',
                    value: '03'
                },
                {
                    label: '04:00',
                    value: '04'
                },
                {
                    label: '05:00',
                    value: '05'
                },
                {
                    label: '06:00',
                    value: '06'
                },
                {
                    label: '07:00',
                    value: '07'
                },
                {
                    label: '08:00',
                    value: '08'
                },
                {
                    label: '09:00',
                    value: '09'
                },
                {
                    label: '10:00',
                    value: '10'
                },
                {
                    label: '11:00',
                    value: '11'
                },
                {
                    label: '12:00',
                    value: '12'
                },
                {
                    label: '13:00',
                    value: '13'
                },
                {
                    label: '14:00',
                    value: '14'
                },
                {
                    label: '15:00',
                    value: '15'
                },
                {
                    label: '16:00',
                    value: '16'
                },
                {
                    label: '17:00',
                    value: '17'
                },
                {
                    label: '18:00',
                    value: '18'
                },
                {
                    label: '19:00',
                    value: '19'
                },
                {
                    label: '20:00',
                    value: '20'
                },
                {
                    label: '21:00',
                    value: '21'
                },
                {
                    label: '22:00',
                    value: '22'
                },
                {
                    label: '23:00',
                    value: '23'
                }
            ] as any,
            rules: {
                applyCount: [
                    {
                        required: true,
                        validator: validateLimitCount,
                        trigger: 'change'
                    }
                ],
                entryDay: [
                    {
                        required: true,
                        validator: validateConditionCount,
                        trigger: 'change'
                    }
                ],
                applyQuota: [
                    {
                        required: true,
                        message: '请填写单次最大申请额度',
                        trigger: 'change'
                    }
                ],
                cutOffTime: [
                    {
                        required: true,
                        message: '请选择每日财务结算时间',
                        trigger: 'change'
                    }
                ],
                applyProjectType: [
                    {
                        required: true,
                        message: '请选择每日财务结算时间',
                        trigger: 'change'
                    }
                ],
                isAutoReject: [
                    {
                        required: true,
                        message: '请选择是否自动驳回',
                        trigger: 'change'
                    }
                ]
            },
            companyName: {
                selectValue: '',
                options: [
                    {
                        label: '1',
                        value: 1
                    }
                ]
            },
            account: {
                value: 0,
                min: 0,
                max: Infinity,
                disabled: false,
                options: [
                    {
                        name: '不限',
                        value: 0
                    },
                    {
                        name: '每天',
                        value: 1
                    },
                    {
                        name: '每周',
                        value: 2
                    },
                    {
                        name: '每月',
                        value: 3
                    }
                ]
            },
            projectLimit: {
                value: '',
                options: [
                    {
                        label: '不限制',
                        value: 99
                    },
                    {
                        label: '无打卡',
                        value: 0
                    },
                    {
                        label: '需打卡',
                        value: 1
                    }
                ]
            },
            tableData: [],
            tableHead: [
                {
                    prop: 'serveCompanyName',
                    label: '企业名称'
                },
                {
                    prop: 'contacts',
                    label: '负责人'
                },
                {
                    prop: 'operate',
                    label: '操作'
                }
            ],
            showCompnay: false,
            companyList: []
        }
    },
    components: {
        tableComponent,
        dialogAdvanceCompany
    },
    computed: {
        applyName() {
            return (val: string) => {
                // 申请限制次数类型 0不限，1每天，2每周，3每月
                const applyMap = {
                    0: '不限',
                    1: '每天',
                    2: '每周',
                    3: '每月'
                } as any
                return applyMap[val]
            }
        },
        projectName() {
            return (val: string) => {
                // 申请项目限制(0：日结 1：长期,不限制：99)
                const applyMap = {
                    0: '无打卡',
                    1: '需打卡',
                    99: '不限制'
                } as any
                return applyMap[val]
            }
        }
    },
    mounted() {
        this.formData = cloneDeep(initData)
        this.getData()
    },
    methods: {
        getData() {
            getAdvanceConfig({}).then((res: any) => {
                this.hasSet = !!res.id
                this.isFirstTime = !!res.id
                console.log(res, 'sdguyhg')

                if (res.id) {
                    this.cutOffTime = res.cutOffTime
                        ? res.cutOffTime === '-1' ? '不限制' : res.cutOffTime + ':00'
                        : ''
                    // eslint-disable-next-line no-self-assign
                    res.cutOffTime = res.cutOffTime
                    this.ruleData = res
                    this.tableData = res.serveCompanyList || []
                    // 预支企业数据
                    this.companyList = cloneDeep(res.serveCompanyList) || []
                    // 设置时的数据
                    for (const key in this.formData) {
                        this.formData[key] = res[key]
                    }
                    for (const key in initData) {
                        initData[key] = res[key]
                    }
                }
            })
        },
        setRule(data: any) {
            setAdvanceConfig(data).then((res: any) => {
                if (res) {
                    this.hasSet = true
                    this.getData()
                }
            })
        },
        handleCountChange(ev: number) {
            console.log(ev)
        },
        handleConditionChange(ev: number) {
            console.log(222, ev)
        },
        handlePeriodChange(item: any) {
            // 不限input做个限制
            if (item === 0) {
                this.account.disabled = true
                this.formData.applyCount = 0
            } else {
                this.account.disabled = false
                this.formData.applyCount = this.formData.applyCount || 1
            }
        },
        handleConfirm() {
            (this.$refs as any).ruleFormRef.validate(
                (valid: any, fields: any) => {
                    if (valid) {
                        const query = {
                            ...this.formData
                        }
                        query.cutOffTime = query.cutOffTime.replace(
                            /:\d{2}/g,
                            ''
                        )
                        this.setRule(query)
                    } else {
                        console.log('error submit!', fields)
                    }
                }
            )
        },
        handleReset() {
            this.formData = cloneDeep(initData)
        },
        handleRemove(row: any) {
            ElMessageBox.confirm('确定删除该条数据吗？', '提示', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(async () => {
                deleteAdvanceCompany({
                    ids: [row.id]
                }).then((res) => {
                    this.$message.success('删除成功！')
                    this.getData()
                })
            })
        }
    }
})
