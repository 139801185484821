
import { defineComponent } from 'vue'
import { cloneDeep } from 'lodash'

export default defineComponent({
    props: {
        tableHead: {
            type: Array,
            default() {
                return []
            }
        },
        tableData: {
            type: Array,
            default() {
                return []
            }
        },
        showPagination: {
            type: Boolean,
            default: true
        },
        pageInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        rowKey: {
            type: [String, Function],
            default: ''
        },
        selectable: {
            type: Function
        },
        description: {
            type: String,
            default: '暂无数据'
        }
    },
    data() {
        return {
            searchData: [],
            pageData: {} as any
        }
    },
    watch: {
        searchList: {
            handler(newval: any) {
                this.searchData = cloneDeep(newval)
            },
            immediate: true
        },
        pageInfo: {
            handler(newval: any) {
                this.pageData = cloneDeep(newval)
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        handleSizeChange(ev: any) {
            this.pageData.pageSize = ev
            this.$emit('update:pageInfo', this.pageData)
            this.$emit('sizeChange', ev)
        },
        handleCurrentChange(ev: any) {
            this.pageData.pageNum = ev
            this.$emit('update:pageInfo', this.pageData)
            this.$emit('currentChange', ev)
        },
        handleSelectionChange(selections: any) {
            this.$emit('selectionChange', selections)
        },
        doSelectable(row: any, index: any) {
            if ((this as any).selectable) {
                // console.log(2121, row, index);
                return (this as any).selectable(row, index)
            }
            return true
        }
    }
})
